/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

require('../css/global.scss');
//require('../../node_modules/bootstrap/dist/css/bootstrap.min.css')
//require('../css/bootstrap_theme.css');
//require('bootstrap/dist/css/bootstrap.css');
// any CSS you require will output into a single css file (app.css in this case)
require('cropperjs/dist/cropper.css');
//require('cropperjs/dist/cropper.min.css');
require('select2/dist/css/select2.css');
//require('select2/dist/css/select2.min.css');
//require('../../public/bundles/prestaimage/css/cropper.css');

//require('../bootstrap-tagsinput/dist/bootstrap-tagsinput.css');
require('../Bootstrap-4-Tag-Input-Plugin-jQuery/tagsinput.css');
require('../../src/Vinch2k/ElementBundle/Resources/public/backend.css');


require('../css/app.css');
require('../css/app_status.css');
require('../css/app_backend.css');
require('../css/app_content.css');
require('ekko-lightbox/dist/ekko-lightbox.css');


// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
global.$ = global.jQuery = $;
var sortable = require( "jquery-ui/ui/widgets/sortable" );
var draggable = require( "jquery-ui/ui/widgets/draggable" );
var droppable = require( "jquery-ui/ui/widgets/droppable" );

require('bootstrap');
require('cropper');
require('popper.js');
require('select2');

//require('../bootstrap-tagsinput/dist/bootstrap-tagsinput.min.js');
require('../Bootstrap-4-Tag-Input-Plugin-jQuery/tagsinput.js');
require('ekko-lightbox');


// Import TinyMCE
//require('tinymce/tinymce');
//require('tinymce/jquery.tinymce')
// A theme is also required
//require('tinymce/themes/silver');

// Any plugins you want to use has to be imported
//require('tinymce/plugins/paste');
//require('tinymce/plugins/link');
/*
tinymce.init({
  selector: '.tinymce',
  plugins: ['paste', 'link']
});
*/

// or you can include specific pieces
//require('bootstrap/js/dist/tooltip');
//require('bootstrap/js/dist/popover');

$(document).ready(function() {
//    $('[data-toggle="popover"]').popover();
});

console.log('Hello Webpack Encore! Edit me in assets/js/app_backend.js');
